/* You can add global styles to this file and also import other style files */

.mainColor {
    color: #000000;
}

:focus {
    outline: none;
}

body {
    background-color: #fafafa;
}

#searchBtn {
    margin-top: 1em
}

.btn-primary,
.btn {
    text-transform: none;
    background-color: #000000;
    border-color: #000000;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Source Sans Pro", Metropolis, "Avenir Next", "Helvetica Neue",
        Arial, sans-serif;
    color: #565656;
}

.spinner
{
    background: url('assets/images/spinner.svg');
}

.btn-sm:not(.btn-link):not(:disabled):not(:hover) clr-icon
{
    color: #ffffff;
}

.signInContainer {
    margin-top: 120px;
  }
  
.signInPanel {
    width: 520px;
    background-color: #f9f9f9;
    border: solid 1px #dddddd;
    border-radius: 5px;
    margin: auto;
    justify-content: center;
    text-align: center;
    vertical-align: auto;
    padding: 48px;
}

.signInPanel > button
{
    margin-top: 48px;
    width: 100%;
}

.signInLogo {
    display: block;
    height: 35px;
}

.btn,
.btn-sm,
.btn.btn-sm {
    text-transform: none;
    font-size: 0.5833rem;
    font-weight: 600;
}

.datagrid-foot {
    font-size: 0.5833rem;
}

.btn-sm:not(:disabled),
.btn-primary:not(:disabled),
.btn:not(:disabled),
.btn-primary.btn-sm:not(.btn-link):not(:disabled) {
    background-color: #373a36;
    border-color:  #373a36;
    color: #ffffff;
}

.btn-sm:not(:disabled) clr-icon,
.btn-primary:not(:disabled) clr-icon,
.btn:not(:disabled) clr-icon,
.btn-primary.btn-sm:not(.btn-link):not(:disabled) clr-icon,
.btn-sm:not(.btn-link):not(:disabled) clr-icon
{
    color: #ffffff;
}

.btn-secondary:not(:disabled),
.btn-secondary.btn-sm:not(.btn-link):not(:disabled),
clr-datagrid .btn:not(:disabled):not(.btn-primary),
clr-datagrid .btn-sm:not(:disabled):not(.btn-primary) {
    background-color: #f9f9f9;
    border-color:  #898a8d;
    color: #373a36;
}

.btn-secondary:not(:disabled) clr-icon,
.btn-secondary.btn-sm:not(.btn-link):not(:disabled) clr-icon,
clr-datagrid .btn:not(:disabled):not(.btn-primary) clr-icon,
clr-datagrid .btn-sm:not(:disabled):not(.btn-primary) clr-icon
{
    color: #373a36 !important;
}

.btn-sm:not(:disabled):hover,
.btn-primary:not(:disabled):hover,
.btn:not(:disabled):hover,
.btn-primary.btn-sm:not(.btn-link):not(:disabled):hover,
.btn-secondary:not(:disabled):hover,
.btn-secondary.btn-sm:not(.btn-link):not(:disabled):hover,
clr-datagrid .btn:not(:disabled):not(.btn-primary):hover,
clr-datagrid .btn-sm:not(:disabled):not(.btn-primary):hover {
    background-color:#000000;
    border-color:  #000000;
    color: #ffffff !important;
}

.btn-sm:not(:disabled):hover clr-icon,
.btn-primary:not(:disabled):hover clr-icon,
.btn:not(:disabled):hover clr-icon,
.btn-primary.btn-sm:not(.btn-link):not(:disabled):hover clr-icon,
.btn-sm:not(.btn-link):not(:disabled):hover clr-icon,
.btn-secondary:not(:disabled):hover clr-icon,
.btn-secondary.btn-sm:not(.btn-link):not(:disabled):hover clr-icon,
clr-datagrid .btn:not(:disabled):not(.btn-primary):hover clr-icon,
clr-datagrid .btn-sm:not(:disabled):not(.btn-primary):hover clr-icon
{
    color: #ffffff !important;
}

.btn.btn-icon > clr-icon + span {
    margin-left: 0.166rem;
}

.btn:disabled {
    border-color: transparent !important;
    background-color: transparent !important;
    color: #979797;
}

.btn:disabled clr-icon {
    color: #979797;
}

.btn-separated {
    margin-left: 30px;
}

.checkbox.btn input[type="checkbox"]:checked + label,
.checkbox-inline.btn input[type="checkbox"]:checked + label {
    background-color: #373a36;
}

.clr-toggle-wrapper input[type=checkbox]:checked + label::before {
    background-color: #373a36;
    border-color: #373a36;
}

.checkbox input[type="checkbox"]:checked + label::before {
    background-color: #373a36;
}

.pagination-list .pagination-current {
    font-weight: 600;
    border-bottom: 2px solid #000000;
}

input[type=date]:not([readonly]),
input[type=datetime-local]:not([readonly]),
input[type=email]:not([readonly]),
input[type=number]:not([readonly]),
input[type=password]:not([readonly]),
input[type=tel]:not([readonly]),
input[type=text]:not([readonly]),
input[type=time]:not([readonly]),
input[type=url]:not([readonly]) {
    background-image: linear-gradient(transparent 95%, rgb(0, 0, 0) 0px);
}

input[type=date]:not([readonly]):focus,
input[type=datetime-local]:not([readonly]):focus,
input[type=email]:not([readonly]):focus,
input[type=number]:not([readonly]):focus,
input[type=password]:not([readonly]):focus,
input[type=tel]:not([readonly]):focus,
input[type=text]:not([readonly]):focus,
input[type=time]:not([readonly]):focus,
input[type=url]:not([readonly]):focus {
    border-bottom: 1px solid #000000;
}

.datagrid-detail-pane-close button.btn.btn-link {
    padding: 0;
    margin-right: 1.2rem;
}