.cls-2 {

    stroke: red;

}
/*
* updated header color
*/
header,
header.header-4,
.header.header-4 {
    background-color: #000000;
}

/*
* update buttons color
*/
.btn {
    border-color: transparent;
    color: #313131;
    padding: 0 10px;
    margin: 1px 1px 1px 0;
}
.btn:visited {
    color: #313131;
}
.btn:hover {
    color: #313131;
    background-color: #eeeeee;
}

.btn.signpost-trigger {
    padding: 0 10px;
    margin: 1px 1px 1px 0;
}

.btn.btn-secondary,
.btn.btn-info,
.btn.btn-outline,
.btn.btn-primary-outline,
.btn.btn-secondary-outline,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-info-outline,
.btn-secondary .btn,
.btn-info .btn,
.btn-outline .btn,
.btn-primary-outline .btn,
.btn-secondary-outline .btn,
.btn-outline-primary .btn,
.btn-outline-secondary .btn,
.btn-info-outline .btn,
.btn-outline-info .btn {
    color: #313131;
}
.btn.btn-secondary,
.btn.btn-info,
.btn-secondary .btn,
.btn-info .btn{
    border-color: transparent;
}

.btn.btn-secondary:not(:disabled) clr-icon,
.btn.btn-secondary.btn-sm:not(:disabled) clr-icon,
.btn.btn-info:not(:disabled) clr-icon,
.btn.btn-outline:not(:disabled) clr-icon,
.btn.btn-primary-outline:not(:disabled) clr-icon,
.btn.btn-secondary-outline:not(:disabled) clr-icon,
.btn.btn-outline-primary:not(:disabled) clr-icon,
.btn.btn-outline-secondary:not(:disabled) clr-icon,
.btn.btn-info-outline:not(:disabled) clr-icon,
.btn-secondary .btn:not(:disabled) clr-icon,
.btn-secondary.btn-sm .btn:not(:disabled) clr-icon,
.btn-info .btn:not(:disabled) clr-icon,
.btn-outline .btn:not(:disabled) clr-icon,
.btn-primary-outline .btn:not(:disabled) clr-icon,
.btn-secondary-outline .btn:not(:disabled) clr-icon,
.btn-outline-primary .btn:not(:disabled) clr-icon,
.btn-outline-secondary .btn:not(:disabled) clr-icon,
.btn-info-outline .btn:not(:disabled) clr-icon,
.btn-outline-info .btn:not(:disabled) clr-icon,
.btn-sm:not(.btn-link):not(:disabled) clr-icon {
    color: #000000;
}
.btn.btn-secondary:visited,
.btn.btn-info:visited,
.btn.btn-outline:visited,
.btn.btn-primary-outline:visited,
.btn.btn-secondary-outline:visited,
.btn.btn-outline-primary:visited,
.btn.btn-outline-secondary:visited,
.btn.btn-info-outline:visited,
.btn-secondary .btn:visited,
.btn-info .btn:visited,
.btn-outline .btn:visited,
.btn-primary-outline .btn:visited,
.btn-secondary-outline .btn:visited,
.btn-outline-primary .btn:visited,
.btn-outline-secondary .btn:visited,
.btn-info-outline .btn:visited,
.btn-outline-info .btn:visited {
    color: #313131;
}

.btn.btn-secondary clr-icon:visited,
.btn.btn-secondary.btn-sm clr-icon:visited,
.btn.btn-info clr-icon,
.btn.btn-outline clr-icon,
.btn.btn-primary-outline clr-icon:visited,
.btn.btn-secondary-outline clr-icon:visited,
.btn.btn-outline-primary clr-icon:visited,
.btn.btn-outline-secondary clr-icon:visited,
.btn.btn-info-outline clr-icon:visited,
.btn-secondary .btn clr-icon:visited,
.btn-secondary.btn-sm .btn clr-icon:visited,
.btn-info .btn clr-icon,
.btn-outline .btn clr-icon,
.btn-primary-outline .btn clr-icon:visited,
.btn-secondary-outline .btn clr-icon:visited,
.btn-outline-primary .btn clr-icon:visited,
.btn-outline-secondary .btn clr-icon:visited,
.btn-info-outline .btn clr-icon:visited,
.btn-outline-info .btn clr-icon:visited,
.btn-sm:not(.btn-link) clr-icon:visited {
    color: #000000;
}

.btn.btn-secondary clr-icon:disabled,
.btn.btn-secondary.btn-sm clr-icon:disabled,
.btn.btn-info clr-icon:disabled,
.btn.btn-outline clr-icon:disabled,
.btn.btn-primary-outline clr-icon:disabled,
.btn.btn-secondary-outline clr-icon:disabled,
.btn.btn-outline-primary clr-icon:disabled,
.btn.btn-outline-secondary clr-icon:disabled,
.btn.btn-info-outline clr-icon:disabled,
.btn-secondary .btn clr-icon:disabled,
.btn-secondary.btn-sm .btn clr-icon:disabled,
.btn-info .btn clr-icon:disabled,
.btn-outline .btn clr-icon:disabled,
.btn-primary-outline .btn clr-icon:disabled,
.btn-secondary-outline .btn clr-icon:disabled,
.btn-outline-primary .btn clr-icon:disabled,
.btn-outline-secondary .btn clr-icon:disabled,
.btn-info-outline .btn clr-icon:disabled,
.btn-outline-info .btn clr-icon:disabled,
.btn-sm:not(.btn-link) clr-icon :disabled {
    color: #313131 !important;
}

.btn.btn-secondary:hover,
.btn.btn-info:hover,
.btn.btn-outline:hover,
.btn.btn-primary-outline:hover,
.btn.btn-secondary-outline:hover,
.btn.btn-outline-primary:hover,
.btn.btn-outline-secondary:hover,
.btn.btn-info-outline:hover,
.btn-secondary .btn:hover,
.btn-info .btn:hover,
.btn-outline .btn:hover,
.btn-primary-outline .btn:hover,
.btn-secondary-outline .btn:hover,
.btn-outline-primary .btn:hover,
.btn-outline-secondary .btn:hover,
.btn-info-outline .btn:hover,
.btn-outline-info .btn:hover {
    color: #293c4f;
    background-color: #eeeeee;
}

.btn.btn-primary,
.btn-primary .btn {
    border-color: #373a36;
    background-color: #373a36;
}
.btn.btn-primary:hover,
.btn-primary .btn:hover {
    background-color: #000000;
}

.btn.btn-outline,
.btn.btn-outline .btn,
.btn.btn-info-outline,
.btn-info-outline .btn,
.btn.btn-outline-info,
.btn-outline .btn {
    border-color: #313131;
    color: #313131;
}
.btn.btn-outline:visited,
.btn.btn-outline .btn:visited,
.btn.btn-info-outline:visited,
.btn-info-outline .btn:visited,
.btn.btn-outline-info:visited,
.btn-outline .btn:visited {
    color: #313131;
}
.btn.btn-outline:hover,
.btn.btn-outline .btn:hover,
.btn.btn-info-outline:hover,
.btn-info-outline .btn:hover,
.btn.btn-outline-info:hover,
.btn-outline .btn:hover {
    background-color: #eeeeee;
}
.btn.btn-link,
.btn-link .btn {
    border-color: transparent;
    background-color: transparent;
    color: #313131;
}
.btn.btn-link:visited,
.btn-link .btn:visited {
    color: #313131;
}
.btn.btn-link:hover,
.btn-link .btn:hover {
    background-color: #eeeeee;
    color: #313131;
}
.btn-group.btn-primary .dropdown-toggle,
.btn-group.btn-success .dropdown-toggle,
.btn-group.btn-warning .dropdown-toggle,
.btn-group.btn-danger .dropdown-toggle {
    border-color: #313131;
    background-color: #313131;
}
.btn-group.btn-primary .dropdown-toggle:hover,
.btn-group.btn-success .dropdown-toggle:hover,
.btn-group.btn-warning .dropdown-toggle:hover,
.btn-group.btn-danger .dropdown-toggle:hover {
    background-color: #293c4f;
}
.btn-group.btn-link .dropdown-toggle {
    color: #373a36;
}
.btn-group.btn-link .dropdown-toggle:visited {
    color: #373a36;
}
.btn-group.btn-link .dropdown-toggle:hover {
    color: #000000;
}

.btn-icon.disabled,
.btn-icon:disabled {
    border-color: transparent;
}
.btn.disabled,
.btn:disabled,
.btn.btn-secondary:disabled {
    border-color: transparent;
    background-color: transparent;
}

.nav .nav-link.active,
.nav .nav-link:hover {
    -webkit-box-shadow: 0 -3px 0 #000000 inset;
    box-shadow: 0 -3px 0 #000000 inset;
}

.checkbox.btn input[type="checkbox"]:checked + label,
.checkbox-inline.btn input[type="checkbox"]:checked + label {
    background-color: #373a36;
}

.checkbox.btn.btn-secondary input[type="checkbox"]:checked + label,
.checkbox.btn.btn-info input[type="checkbox"]:checked + label,
.checkbox.btn.btn-outline input[type="checkbox"]:checked + label,
.checkbox.btn.btn-primary-outline input[type="checkbox"]:checked + label,
.checkbox.btn.btn-secondary-outline input[type="checkbox"]:checked + label,
.checkbox.btn.btn-outline-primary input[type="checkbox"]:checked + label,
.checkbox.btn.btn-outline-secondary input[type="checkbox"]:checked + label,
.checkbox.btn.btn-info-outline input[type="checkbox"]:checked + label,
.checkbox.btn.btn-outline-info input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-secondary input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-info input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-outline input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-primary-outline input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-secondary-outline
    input[type="checkbox"]:checked
    + label,
.checkbox-inline.btn.btn-outline-primary input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-outline-secondary
    input[type="checkbox"]:checked
    + label,
.checkbox-inline.btn.btn-info-outline input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-outline-info input[type="checkbox"]:checked + label {
    background-color: #373a36;
}

.checkbox.btn.btn-link input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-link input[type="checkbox"]:checked + label {
    color: #373a36;
}

.clr-checkbox-wrapper input[type="checkbox"]:checked + label::before {
    background-color: #000000;
}

.radio.btn.btn-secondary input[type="radio"]:checked + label,
.radio.btn.btn-info input[type="radio"]:checked + label,
.radio.btn.btn-outline input[type="radio"]:checked + label,
.radio.btn.btn-primary-outline input[type="radio"]:checked + label,
.radio.btn.btn-secondary-outline input[type="radio"]:checked + label,
.radio.btn.btn-outline-primary input[type="radio"]:checked + label,
.radio.btn.btn-outline-secondary input[type="radio"]:checked + label,
.radio.btn.btn-info-outline input[type="radio"]:checked + label,
.radio.btn.btn-outline-info input[type="radio"]:checked + label,
.radio.btn.btn-secondary input[type="radio"]:checked + label,
.radio.btn.btn-info input[type="radio"]:checked + label,
.radio.btn.btn-outline input[type="radio"]:checked + label,
.radio.btn.btn-primary-outline input[type="radio"]:checked + label,
.radio.btn.btn-secondary-outline input[type="radio"]:checked + label,
.radio.btn.btn-outline-primary input[type="radio"]:checked + label,
.radio.btn.btn-outline-secondary input[type="radio"]:checked + label,
.radio.btn.btn-info-outline input[type="radio"]:checked + label,
.radio.btn.btn-outline-info input[type="radio"]:checked + label {
    background-color: #373a36;
}
.clr-radio-wrapper input[type=radio]:checked+label::before {
    -webkit-box-shadow: inset 0 0 0 0.25rem black !important;
    box-shadow: inset 0 0 0 0.25rem black !important;
}

.modal-title {
    word-wrap: break-word;
}

/*
custom fonts
*/
body,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Source Sans Pro", Metropolis, "Avenir Next", "Helvetica Neue",
        Arial, sans-serif;
    color: #565656;
}

span.nav-text, .nav .nav-link,
.nav-group-text{
    font-size: 16px;
}
.nav-link{
    font-size: 15px;
}
.clr-timeline-step-title, .datagrid-table .datagrid-cell {
    font-size: 14px;
}
.clr-timeline-step-description {
    font-size: 12px;
}

.main-container.authorized {
    background-color: white;
}
.main-container.not-authorized {
    background-image: url("../assets/images/bg.jpg");
}

.navigation {
    background-color: #f9f9f9;
    border-right: solid 1px #dddddd;
}

.clr-vertical-nav .nav-link.active .nav-icon,
.clr-vertical-nav .nav-group.active:not(.is-expanded) .nav-group-content .nav-icon {
    fill: #000000;
}

.clr-vertical-nav.has-nav-groups .nav-group .nav-group-text:not(.active),
.clr-vertical-nav.has-nav-groups .nav-group .nav-group-trigger:not(.active),
.clr-vertical-nav.has-nav-groups .nav-link:not(.active) {
    font-weight: inherit;
}
.nav-link.active,
.clr-vertical-nav.has-nav-groups .nav-group .nav-group-text,
.clr-vertical-nav.has-nav-groups .nav-group .nav-group-trigger,
.clr-vertical-nav.has-nav-groups .nav-link,
.clr-vertical-nav .nav-group.active:not(.is-expanded) .nav-group-content,
.clr-vertical-nav.has-nav-groups .nav-group-children .nav-link.active {
    font-weight: bold;
}
.header .header-actions .nav-link,
.header .header-actions > .dropdown > .dropdown-toggle {
    opacity: 1;
    color: #ffffff;
}

.header .header-actions .nav-link :hover,
.header .header-actions > .dropdown > .dropdown-toggle :hover,
.header .header-actions .nav-link:hover {
    opacity: 0.8;
}

h2 {
    margin-top: 0;
}

button:focus {
    outline: transparent;
}

.btn-info .btn:active,
.btn-outline-info .btn:active,
.btn-outline-primary .btn:active,
.btn-outline-secondary .btn:active,
.btn-primary-outline .btn:active,
.btn-secondary .btn:active,
.btn-secondary-outline .btn:active,
.btn.btn-info:active,
.btn.btn-outline-primary:active,
.btn.btn-outline-secondary:active,
.btn.btn-primary-outline:active,
.btn.btn-secondary-outline:active,
.btn.btn-secondary:active,
.btn:active {
    -webkit-box-shadow: 0 0 0 0 #0092d1 inset;
    box-shadow: 0 0 0 0 #0092d1 inset;
}


.label,
.datagrid .datagrid-column {
    font-size: 0.5rem;
}

a.alert-action {
    cursor: pointer;
}



.clr-input:not([readonly]).clr-focus, .clr-input:not([readonly]):focus {
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: 0 -1px 0 #000000 inset;
    box-shadow: 0 -1px 0 #000000 inset;
    background: -webkit-gradient(linear,left top, left bottom,color-stop(95%, transparent),color-stop(95%, #000000)) no-repeat;
    background: linear-gradient(to bottom,transparent 95%,#000000 95%) no-repeat;
}
.clr-textarea:focus, .clr-toggle-wrapper input[type="checkbox"]:focus + label::before {

    outline: 0;
    -webkit-box-shadow: 0 0 1px 1px #000000;
    box-shadow: 0 0 1px 1px #000000;

}
.clr-checkbox-wrapper input[type=checkbox]:indeterminate+label::after{
    border-bottom-color: #000000;

}
.clr-checkbox-wrapper input[type=checkbox].clr-indeterminate+label::before, .clr-checkbox-wrapper input[type=checkbox]:indeterminate+label::before {
    border: 1px solid #000000;
}
.tooltip-content.disabled,
.tooltip-content:disabled{
    opacity: 1;
}
