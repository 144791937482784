.mainColor {
    color: #007cbb;
}

.errorColor {
    color: #CD3517;
}

.okColor {
    color: #60B515;
}

.mainBorder {
    border: 1px solid #007cbb;
    border-radius: 3px;
}

.errorBorder {
    border: 1px solid #c92100;
    border-radius: 3px;
}

.okLeftBorder {
    border-left: 3px solid #60B515;
    padding-left: 10px;
}

.errorLeftBorder {
    border-left: 3px solid #c92100;
    padding-left: 10px;
}

.errorBackgroundColor {
    background-color: #f5dbd9;
}

.errorBlock {
    width:100%;
    border: 1px solid #ebafa6;
    background-color: #f5dbd9;
    border-radius:2px;
    padding: 2px 10px 2px 10px;
    margin: 7px 0px 7px 0px;
}

.warningBlock {
    width:100%;
    border: 1px solid #ffbf75;
    background-color: #fadcba;
    border-radius:2px;
    padding: 2px 10px 2px 10px;
    margin: 7px 0px 7px 0px;
}

.centerText {
    text-align: center;
}

.noBulletList li {
    list-style: none;
}

/* Table with border */
table.table_with_space tr td {
    padding-right: 20px;
}

/* datagrid disabled row */
.selectDisabled {
    opacity: 0.5;
}

.selectDisabled .checkbox {
    display: none !important;
}

.selectAllDisabled .datagrid-head .checkbox { /* if you use selectDisabled use this one https://github.com/vmware/clarity/issues/1550 */
    display: none !important;
}

/* datagrid in cards have an unwanted top-margin*/
.card-block .noDatagridTopMargin .datagrid {
    margin-top: 0px;
}

/* datagrid: style for one button icon action column*/
.datagrid .datagrid-column.actionColumn, .datagrid .datagrid-cell.actionColumn {
    min-width:50px; 
    width:50px;
    max-width:50px;
}

/* To change next button color to red when wanted. Need to add the redNextButton class to the nextButton. */
clr-wizard-button.redNextButton .btn.btn-primary {
    background-color: #e62700;
    border-color: #e62700;
}

input.clr-input {
  margin-right: 0.5rem;
}

.clr-control-container .clr-select-wrapper,
.clr-control-container .clr-select-wrapper select {
  max-width: 100%;
}

.clr-form-control.full-width .clr-control-container .clr-select-wrapper,
.clr-form-control.full-width .clr-control-container .clr-select-wrapper select {
  width: 100%;
}

.clr-form-control.full-width .clr-control-container {
  max-width: 100%;
  width: 100%;
}

.clr-form-control.full-width .clr-input,
.clr-form-control.full-width .clr-input-wrapper {
  max-width: 80%;
  width: 80%;
}

.clr-form-control.full-width .clr-control-container,
.clr-form-control.full-width .clr-input-wrapper.clr-input {
  padding-left: 0;
  padding-right: 0;
}

/*
*
*
*
*
*/
/*
* updated header color
*/
header,
  header.header-4,
  .header.header-4 {
    background-color: #334960; }

/*
* update buttons color
*/
.btn {
  border-color: #446282;
  color: #446282; }
  .btn:visited {
    color: #446282; }
  .btn:hover {
    color: #293c4f; }

.btn.btn-secondary,
.btn.btn-info,
.btn.btn-outline,
.btn.btn-primary-outline,
.btn.btn-secondary-outline,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-info-outline,
.btn-secondary .btn,
.btn-info .btn,
.btn-outline .btn,
.btn-primary-outline .btn,
.btn-secondary-outline .btn,
.btn-outline-primary .btn,
.btn-outline-secondary .btn,
.btn-info-outline .btn,
.btn-outline-info .btn {
  border-color: #446282;
  color: #446282; }
  .btn.btn-secondary:visited,
  .btn.btn-info:visited,
  .btn.btn-outline:visited,
  .btn.btn-primary-outline:visited,
  .btn.btn-secondary-outline:visited,
  .btn.btn-outline-primary:visited,
  .btn.btn-outline-secondary:visited,
  .btn.btn-info-outline:visited,
  .btn-secondary .btn:visited,
  .btn-info .btn:visited,
  .btn-outline .btn:visited,
  .btn-primary-outline .btn:visited,
  .btn-secondary-outline .btn:visited,
  .btn-outline-primary .btn:visited,
  .btn-outline-secondary .btn:visited,
  .btn-info-outline .btn:visited,
  .btn-outline-info .btn:visited {
    color: #446282; }
  .btn.btn-secondary:hover,
  .btn.btn-info:hover,
  .btn.btn-outline:hover,
  .btn.btn-primary-outline:hover,
  .btn.btn-secondary-outline:hover,
  .btn.btn-outline-primary:hover,
  .btn.btn-outline-secondary:hover,
  .btn.btn-info-outline:hover,
  .btn-secondary .btn:hover,
  .btn-info .btn:hover,
  .btn-outline .btn:hover,
  .btn-primary-outline .btn:hover,
  .btn-secondary-outline .btn:hover,
  .btn-outline-primary .btn:hover,
  .btn-outline-secondary .btn:hover,
  .btn-info-outline .btn:hover,
  .btn-outline-info .btn:hover {
    color: #293c4f; }

.btn.btn-primary,
.btn-primary .btn {
  border-color: #446282;
  background-color: #446282; }
  .btn.btn-primary:hover,
  .btn-primary .btn:hover {
    background-color: #293c4f;}

.btn.btn-outline,
.btn.btn-outline .btn,
.btn.btn-info-outline,
.btn-info-outline .btn,
.btn.btn-outline-info,
.btn-outline .btn {
  border-color: #446282;
  color: #446282; }
  .btn.btn-outline:visited,
  .btn.btn-outline .btn:visited,
  .btn.btn-info-outline:visited,
  .btn-info-outline .btn:visited,
  .btn.btn-outline-info:visited,
  .btn-outline .btn:visited {
    color: #446282; }
  .btn.btn-outline:hover,
  .btn.btn-outline .btn:hover,
  .btn.btn-info-outline:hover,
  .btn-info-outline .btn:hover,
  .btn.btn-outline-info:hover,
  .btn-outline .btn:hover {
    color: #293c4f; }
.btn.btn-link,
.btn-link .btn {
  border-color: transparent;
  background-color: transparent;
  color: #446282; }
  .btn.btn-link:visited,
  .btn-link .btn:visited {
    color: #446282; }
  .btn.btn-link:hover,
  .btn-link .btn:hover {
    background-color: transparent;
    color: #293c4f; }
.btn-group.btn-primary .dropdown-toggle,
.btn-group.btn-success .dropdown-toggle,
.btn-group.btn-warning .dropdown-toggle,
.btn-group.btn-danger .dropdown-toggle {
  border-color: #446282;
  background-color: #446282;}
  .btn-group.btn-primary .dropdown-toggle:hover,
  .btn-group.btn-success .dropdown-toggle:hover,
  .btn-group.btn-warning .dropdown-toggle:hover,
  .btn-group.btn-danger .dropdown-toggle:hover {
    background-color: #293c4f;}
.btn-group.btn-link .dropdown-toggle {
  color: #446282; }
  .btn-group.btn-link .dropdown-toggle:visited {
    color: #446282; }
  .btn-group.btn-link .dropdown-toggle:hover {
    color: #293c4f; }
.checkbox.btn input[type="checkbox"]:checked + label,
.checkbox-inline.btn input[type="checkbox"]:checked + label {
  background-color: #446282;}

.checkbox.btn.btn-secondary input[type="checkbox"]:checked + label, .checkbox.btn.btn-info input[type="checkbox"]:checked + label, .checkbox.btn.btn-outline input[type="checkbox"]:checked + label, .checkbox.btn.btn-primary-outline input[type="checkbox"]:checked + label, .checkbox.btn.btn-secondary-outline input[type="checkbox"]:checked + label, .checkbox.btn.btn-outline-primary input[type="checkbox"]:checked + label, .checkbox.btn.btn-outline-secondary input[type="checkbox"]:checked + label, .checkbox.btn.btn-info-outline input[type="checkbox"]:checked + label, .checkbox.btn.btn-outline-info input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-secondary input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-info input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-outline input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-primary-outline input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-secondary-outline input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-outline-primary input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-outline-secondary input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-info-outline input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-outline-info input[type="checkbox"]:checked + label {
  background-color: #446282;}

.checkbox.btn.btn-link input[type="checkbox"]:checked + label,
.checkbox-inline.btn.btn-link input[type="checkbox"]:checked + label {
  color: #293c4f; }

.radio.btn.btn-secondary input[type="radio"]:checked + label, .radio.btn.btn-info input[type="radio"]:checked + label, .radio.btn.btn-outline input[type="radio"]:checked + label, .radio.btn.btn-primary-outline input[type="radio"]:checked + label, .radio.btn.btn-secondary-outline input[type="radio"]:checked + label, .radio.btn.btn-outline-primary input[type="radio"]:checked + label, .radio.btn.btn-outline-secondary input[type="radio"]:checked + label, .radio.btn.btn-info-outline input[type="radio"]:checked + label, .radio.btn.btn-outline-info input[type="radio"]:checked + label,
.radio.btn.btn-secondary input[type="radio"]:checked + label,
.radio.btn.btn-info input[type="radio"]:checked + label,
.radio.btn.btn-outline input[type="radio"]:checked + label,
.radio.btn.btn-primary-outline input[type="radio"]:checked + label,
.radio.btn.btn-secondary-outline input[type="radio"]:checked + label,
.radio.btn.btn-outline-primary input[type="radio"]:checked + label,
.radio.btn.btn-outline-secondary input[type="radio"]:checked + label,
.radio.btn.btn-info-outline input[type="radio"]:checked + label,
.radio.btn.btn-outline-info input[type="radio"]:checked + label {
  background-color: #446282;}
